<template>
  <div class="border-class pa-1">
    <v-row no-gutters align="center">
      <v-col cols="12" class="text-right">
        <v-btn
          color="accent"
          icon
          class="color-accent-text"
          :disabled="!canEdit"
          @click="deconstructedData.push({ text: type === 'switch' ? 'query' : '', value: '' })"
        >
          +
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" class="pt-2">
      <v-col v-if="duplicatedKeys" cols="12" class="pb-2">
        <v-alert dense color="error" style="color: black">{{ $lang.errors.duplicatedKeys }}</v-alert>
      </v-col>
      <v-col cols="12">
        <v-row no-gutters align="center" class="pb-1">
          <v-col cols="1">
            <span>{{ $lang.header.id }}</span>
          </v-col>
          <v-col cols="5">
            <span>{{ $lang.header.key }}</span>
          </v-col>
          <v-col cols="5" class="px-1">
            <span>{{ $lang.header.value }}</span>
          </v-col>
          <v-col cols="1" class="text-right">
            <span>{{ $lang.header.act }}</span>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <template v-for="(item, i) in deconstructedData">
          <v-row :key="i" no-gutters align="center" class="pb-1">
            <v-col cols="1">
              <span>{{ i + 1 }}</span>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="item.text"
                dense
                :readonly="!canEdit"
                required
                :rules="[v => !!v || 'Required!']"
                :data-cy="`${type}-key-${i}`"
              ></v-text-field>
            </v-col>
            <v-col cols="5" class="px-1">
              <v-text-field
                v-model="item.value"
                dense
                required
                :rules="[v => !!v || 'Required!']"
                :readonly="!canEdit"
                :data-cy="`${type}-value-${i}`"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn
                class="ml-1"
                icon
                small
                :disabled="!canEdit"
                @click="deleteRow(i)"
              >
                <v-icon small color="error">mdi-delete</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return null
      }
    },
    type: {
      type: String,
      default: () => {
        return ''
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data: () => ({
    deconstructedData: [],
    duplicatedKeys: false
  }),
  watch: {
    deconstructedData: {
      handler() {
        const tempData = {}

        this.deconstructedData.forEach((item) => {
          if (item.text && item.value) tempData[item.text] = item.value
        })
        this.selectedKeys = this.deconstructedData.map((x) => x.text)
        this.duplicatedKeys = !!this.hasDuplicates(this.selectedKeys)
        this.$emit('dataChanged', tempData)
      },
      deep: true
    }
  },
  created() {
    for (const row in this.data) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.data.hasOwnProperty(row)) {
        this.deconstructedData.push({ text: row , value: this.data[row] })
      }
    }
  },
  methods: {
    hasDuplicates(arr) {
      return arr.some((x) => arr.indexOf(x) !== arr.lastIndexOf(x))
    },
    deleteRow(i) {
      this.deconstructedData.splice(i, 1)
    }
  }
}
</script>
<style lang="scss" scoped>
.border-class {
  border: 1px var(--v-customInputBorderColor-base) solid;
  border-radius: 6px;
}
</style>
