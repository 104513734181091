<template>
  <v-container fluid>
    <div class="fill-height">
      <h1 class="text-h4">
        {{ setting.type.toLowerCase().replace('_', ' ') | capitalize }} - {{ isEdit ? $lang.labels.details : $lang.actions.create }}
      </h1>
      <v-divider class="my-2"></v-divider>
      <v-row v-if="err || success" dense no-gutters class="px-0">
        <v-col v-if="err" cols="12">
          <v-alert color="error" :data-cy="err">{{ err }}</v-alert>
        </v-col>
        <v-col v-if="success" cols="12">
          <v-alert color="success" :data-cy="success">{{ success }}</v-alert>
        </v-col>
      </v-row>
      <v-row wrap no-gutters class="pb-2 fill-height">
        <v-col
          cols="12"
          sm="12"
          md="8"
          class="fill-height"
        >
          <v-tabs
            v-model="tab"
          >
            <v-tabs-slider color="accent"></v-tabs-slider>

            <v-tab
              :key="0"
              data-cy="tab-details"
            >
              {{ $lang.labels.details }}
            </v-tab>
            <v-tab
              :key="1"
              data-cy="tab-permissions"
            >
              {{ $lang.labels.permissions }}
            </v-tab>
            <v-tab
              :key="2"
              data-cy="tab-history"
            >
              {{ $lang.labels.history }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="pb-6 fill-height">
            <v-tab-item
              :key="0"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height">
                <v-form
                  ref="form"
                  v-model="valid"
                  class="pt-3"
                >
                  <v-row wrap no-gutters justify="space-between" class="pb-1">
                    <v-col
                      cols="12"
                      sm="9"
                      class="pr-sm-1 d-flex"
                    >
                      <v-text-field
                        v-model="setting.name"
                        outlined
                        dense
                        :label="$lang.labels.name"
                        required
                        :rules="[v => !!v || 'Required!']"
                        :persistent-hint="isEdit"
                        :hint="formatHint"
                        :readonly="!userCanEdit || setting.isSystem"
                        class="required-asterisk"
                      ></v-text-field>
                      <v-btn
                        icon
                        color="primary"
                        class="ml-2"
                        style="margin-top: 2px"
                        title="copy"
                        @click="copyField()"
                      >
                        <v-icon>mdi-clipboard-outline</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col v-if="isEdit" cols="3" class="pl-1 text-right">
                      <v-btn
                        class="button-default-width"
                        color="primary"
                        :disabled="!userCanEdit"
                        @click="changeStatus()"
                      >
                        {{ setting.status === 'ACTIVE' ? $lang.actions.deactivate : $lang.actions.activate }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-if="!initLoading" wrap no-gutters>
                    <v-col v-if="setting.type !== 'INSTANCE_ADMIN'" cols="12" class="pb-2">
                      <h4 class="text-h5">{{ $lang.header.values }} - {{ $lang.status[setting.type] }}</h4>
                    </v-col>
                    <values-component
                      :key="valuesKey"
                      :can-edit="userCanEdit"
                      :is-edit="isEdit"
                      :type="setting.type"
                      :default-values="setting.values"
                      @setValues="setting.values = $event"
                    ></values-component>
                  </v-row>
                  <v-row v-if="setting.type === 'INSTANCE_ADMIN' || setting.type === 'INSTANCE_PROCESSING'" no-gutters>
                    <v-col cols="12" class="pb-2">
                      <v-alert dark color="secondary" :data-cy="$lang.hints.eventPermission">
                        <div style="width: 100%" class="">
                          <h4>{{ $lang.labels.healthcheckURL }}:</h4>
                          <p v-if="setting.type === 'INSTANCE_ADMIN'" class="pb-0 mb-0">{{ urlInfo.admin }}</p>
                          <p v-if="setting.type === 'INSTANCE_PROCESSING'" class="pb-0 mb-0">{{ urlInfo.processingService }}</p>
                        </div>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-row v-if="testResult" dense no-gutters class="px-0">
                    <v-col v-if="testResult" cols="12">
                      <v-alert :color="resultColor">{{ testResult }}</v-alert>
                    </v-col>
                  </v-row>
                  <v-row no-gutters wrap justify="space-between">
                    <v-col
                      cols="6"
                      class="text-left"
                    >
                      <div class="d-inline-flex align-center">
                        <v-btn
                          class="button-default-width"
                          color="secondary"
                          :to="{ name: isEdit ? setting.type.toLowerCase().replace('_', '-') : $route.params.type.toLowerCase().replace('_', '-'), params: { lang: $lang.current_lang } }"
                        >
                          <v-icon
                            right
                            dark
                            class="ma-0"
                          >
                            mdi-arrow-left
                          </v-icon>
                          <span class="ml-1">{{ $lang.actions.back }}</span>
                        </v-btn>
                        <action-button-with-confirmation
                          v-if="isEdit"
                          :action-text="$lang.actions.areYouSureYouWantToDelete"
                          :action-text-suffix="setting.name"
                          :title="$lang.actions.delete"
                          type="setting"
                          :is-disabled="!userCanDelete || (setting.isSystem && setting.type === 'FRONTEND')"
                          :button-text="$lang.actions.delete"
                          :button-color="'error'"
                          :data-cy="'setting-delete'"
                          :forced-option="true"
                          :trigger-force-logic="triggerForceLogic"
                          :regular-delete-errors-usages="regularDeleteErrorsUsages"
                          class="ml-2"
                          :delete-success="deleteSuccess"
                          @submit="deleteSettingFunct($event)"
                          @closeDialog="''"
                          @closeAfterDelete="$router.push({ name: setting.type.toLowerCase().replace('_', '-'), params: { lang: $lang.current_lang } })"
                        />
                      </div>
                    </v-col>
                    <v-col
                      cols="6"
                      class="text-right"
                    >
                      <a
                        v-if="(setting.type === 'INSTANCE_PROCESSING' || setting.type === 'INSTANCE_ADMIN') && isEdit"
                        href="#"
                        style="text-decoration: none"
                        @click="openInternalJobs"
                      >
                        Open internal jobs
                      </a>
                      <v-btn
                        :disabled="!valid || !setting.values || lock || !userCanEdit || Object.keys(setting.values).length === 0"
                        color="success"
                        class="button-default-width ml-2"
                        @click="submit()"
                      >
                        <v-icon
                          left
                          dark
                          class="mr-1"
                        >
                          mdi mdi-floppy
                        </v-icon>
                        {{ $lang.actions.submit }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="1"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height">
                <v-row wrap no-gutters class="pb-4">
                  <v-col cols="12" class="pb-2">
                    <div v-if="allRoles && allRoles.length > 0" style="width: 100%; height: 100%">
                      <h3 class="pb-1">{{ $lang.status.EDIT }}</h3>
                      <user-roles-select
                        :role="editRolesIds"
                        :options="allRoles"
                        data-cy="roles-edit"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="editRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.USE }}</h3>
                      <user-roles-select
                        :role="useRolesIds"
                        :options="useRolePool"
                        data-cy="roles-use"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="useRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.VIEW }}</h3>
                      <user-roles-select
                        :role="viewRolesIds"
                        :options="viewRolePool"
                        data-cy="roles-view"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="viewRolesIds = $event"
                      ></user-roles-select>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="2"
              class="fill-height"
            >
              <v-card class="py-1 fill-height">
                <div style="width: 100%; height: 100%">
                  <v-row dense no-gutters>
                    <v-col cols="12">
                      <Table
                        :items="history"
                        @fetchHistory="fetchHistory($event)"
                        @restoreHistory="restoreSetting($event)"
                      ></Table>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-snackbar
        v-model="snackShow"
        :color="snackColor"
        content-class="text-center"
        top
      >
        <span class="color-accent-text">{{ snackbarText }}</span>
      </v-snackbar>
    </div>
  </v-container>
</template>

<script>
import ValuesComponent from './Values'
import Table from '@/components/ui/tables/HistoryDatatable'
import {
  disableSettingUsingGET as disableSetting,
  enableSettingUsingGET as enableSetting,
  getSettingByIdUsingGET as getSetting,
  updateFrontendSettingUsingPUT as updateFrontend,
  createFrontendSettingUsingPOST as createFrontend,
  updateInstanceProcessingSettingUsingPUT as updateInstanceProcessing,
  createInstanceProcessingSettingUsingPOST as createInstanceProcessing,
  updateInstanceAdminSettingUsingPUT as updateInstanceAdmin,
  createInstanceAdminSettingUsingPOST as createInstanceAdmin,
  updateProcessSettingUsingPUT as updateProcessSetting,
  createProcessSettingUsingPOST as createProcessSetting,
  updatePluginSettingUsingPUT as updatePluginSetting,
  createPluginSettingUsingPOST as createPluginSetting,
  getRolesUsingGET as getRoles,
  getHistoriesUsingGET as getHistories,
  deleteSettingUsingDELETE as deleteSetting
} from '@/utils/api'
import UserRolesSelect from '../../components/ui/UserRolesSelect'
import copy from 'copy-to-clipboard'
import ActionButtonWithConfirmation from '@/components/ui/ActionButtonWithConfirmation.vue'

export default {
  components: {
    ActionButtonWithConfirmation,
    ValuesComponent,
    Table,
    UserRolesSelect
  },
  data() {
    return {
      snackbarText: '',
      snackShow: false,
      snackColor: 'success',
      err: '',
      success: '',
      testResult: '',
      resultColor: '',
      tab: 0,
      valid: false,
      isEdit: false,
      loading: false,
      initLoading: true,
      lock: false,
      setting: {
        createdOn: '',
        id: 0,
        modifiedOn: '',
        name: '',
        status: '',
        type: this.$route.params.type ? this.$route.params.type.toUpperCase() : '',
        values: null,
        isSystem: false
      },
      allRoles: [],
      editRolesIds: [],
      useRolesIds: [],
      viewRolesIds: [],
      permissionsTypes: ['EDIT', 'USE', 'VIEW'],
      userRolesIds: [],
      userCanEdit: false,
      isSuperUser: false,
      history: { items: [], meta: {} },
      valuesKey: 0,
      urlInfo: {
        admin: '/api/actuator/health',
        processingService: '/actuator/health'
      },
      userCanDelete: false,
      triggerForceLogic: false,
      regularDeleteErrorsUsages: [],
      deleteSuccess: false
    }
  },
  computed: {
    formatHint() {
      return `${this.$lang.labels.createdOn}: ${this.$options.filters.formatDateTime(this.setting.createdOn)}, ${this.$lang.labels.modifiedOn}: ${this.$options.filters.formatDateTime(this.setting.modifiedOn)}, ID: ${this.setting.id}`
    },
    useRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id))
    },
    viewRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id) && !this.useRolesIds.includes(x.id))
    }
  },
  created() {
    this.isEdit = this.$route.name === 'settingEdit'

    let user = null

    if (localStorage.userData) {
      user = JSON.parse(localStorage.userData)

      this.userRolesIds = user.roles.map((x) => x.id)
      this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
      this.userCanDelete = !!user.roles.find((x) => x.name === 'SETTING_DELETER') || this.isSuperUser
    }

    this.init()
      .then(() => {
        if (this.isEdit && this.$route.params.id) {
          this.loading = true
          getSetting({ id: this.$route.params.id })
            .then((res) => {
              this.setting = res.data.data

              if (this.setting.roles) {
                this.editRolesIds = this.setting.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
                this.useRolesIds = this.setting.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
                this.viewRolesIds = this.setting.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

                this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))
              }

              this.userCanEdit = true

              this.loading = false
              this.initLoading = false
            })
            .catch((err) => {
              this.err = err
            })
        } else {
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          this.userCanEdit = true
          this.loading = false
          this.initLoading = false
        }
      })
  },
  methods: {
    deleteSettingFunct(isForced = false) {
      this.triggerForceLogic = false
      this.regularDeleteErrorsUsages = []
      this.deleteSuccess = false
      deleteSetting({ id: this.setting.id, force: isForced })
        .then((res) => {
          if (res.status === 200) {
            this.deleteSuccess = true
            this.regularDeleteErrorsUsages = res.data.data.usages
          } else {
            this.triggerForceLogic = true
            this.regularDeleteErrorsUsages = res.response.data.data.usages
          }
        })
        .catch((err) => {
          this.err = err
        })
    },
    copyField() {
      if (this.setting.name) {
        copy(this.setting.name)
        this.showSnack(this.$lang.success.copiedClipboard, 'success')
      } else {
        this.showSnack(this.$lang.errors.nothingToCopy, 'warning')
      }
    },
    showSnack(text, color = 'success') {
      this.snackbarText = text
      this.snackColor = color
      this.snackShow = true
    },
    fetchHistory(options) {
      if (!this.setting.id) return

      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      obj.resourceId = this.setting.id
      obj.resourceType = 'SETTING'

      getHistories(obj)
        .then((res) => {
          this.history = res.data.data
        })
        .catch((err) => {
          this.err = err
        })
    },
    restoreSetting(data) {
      this.setting = data

      if (this.setting && this.setting.roles) {
        this.editRolesIds = this.setting.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
        this.useRolesIds = this.setting.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
        this.viewRolesIds = this.setting.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

        this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))
      }
      this.valuesKey++
    },
    init() {
      return new Promise((resolve) => {
        getRoles()
          .then((res) => {
            this.allRoles = res.data.data.items
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })
    },
    async openInternalJobs() {
      const preFillItems = localStorage.getItem('preFillData')
      let payload = {}

      if (preFillItems) {
        const preFillData = JSON.parse(preFillItems)

        payload = {
          ...preFillData,
          instancesList: {
            name: this.setting.name
          }
        }
      } else {
        payload = {
          instancesList: {
            name: this.setting.name
          }
        }
      }

      localStorage.setItem('preFillData', JSON.stringify(payload))
      window.open(`/${this.$lang?.current_lang || 'en'}/logs-internal-jobs`, '_BLANK')
    },
    async submit() {
      this.lock = true

      let res = null

      const { id } = this.setting

      if (!this.isEdit) {
        delete this.setting.id
        this.setting.status = 'ACTIVE'
      }
      delete this.setting.createdOn
      delete this.setting.modifiedOn

      const tempRoles = []

      this.editRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'EDIT', roleId: x })
      })

      this.useRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'USE', roleId: x })
      })

      this.viewRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'VIEW', roleId: x })
      })

      this.setting.roles = tempRoles

      if (this.setting.values.logServerUrl === '') delete this.setting.values.logServerUrl

      try {

        switch (this.setting.type) {
        case 'FRONTEND':
          res = this.isEdit ? await updateFrontend({ id, request: this.setting }) : await createFrontend({ request: this.setting })
          break
        case 'INSTANCE_PROCESSING':
          res = this.isEdit ? await updateInstanceProcessing({ id, request: this.setting }) : await createInstanceProcessing({ request: this.setting })
          break
        case 'INSTANCE_ADMIN':
          res = this.isEdit ? await updateInstanceAdmin({ id, request: this.setting }) : await createInstanceAdmin({ request: this.setting })
          break
        case 'PROCESS':
          res = this.isEdit ? await updateProcessSetting({ id, request: this.setting }) : await createProcessSetting({ request: this.setting })
          break
        case 'PLUGIN':
          res = this.isEdit ? await updatePluginSetting({ id, request: this.setting }) : await createPluginSetting({ request: this.setting })
          break
        default:
          res = null
        }

        if (res && res.status !== 200) {
          this.err = this.isEdit ? this.$lang.errors.settingUpdate : this.$lang.errors.settingCreate
          setTimeout(() => this.err = '', 5000)
          this.lock = false

          return
        }
        this.success = this.isEdit ? this.$lang.success.settingUpdated : this.$lang.success.settingCreated

        this.$router.push({
          name: this.isEdit ? this.setting.type.toLowerCase().replace('_', '-') : this.$route.params.type.toLowerCase().replace('_', '-'),
          params: {
            message: this.success
          }
        })

      } catch (err) {
        this.err = err
        this.lock = false
        setTimeout(() => this.err = '', 5000)
      }
    },
    async changeStatus () {
      this.lock = true
      try {
        const res = this.setting.status === 'ACTIVE' ? await disableSetting({ id: this.setting.id }) : await enableSetting({ id: this.setting.id })

        if (res.status !== 200) {
          this.err = this.$lang.errors.settingStatusUpdate
          setTimeout(() => this.err = '', 5000)
          this.lock = false

          return
        }
        this.success = this.setting.status !== 'ACTIVE' ? this.$lang.success.settingActivated : this.$lang.success.settingDeactivated

        this.setting.status = res.data.data.status

        this.lock = false

        setTimeout(() => this.success = '', 5000)

      } catch (err) {
        this.err = err
        this.lock = false
        setTimeout(() => this.err = '', 5000)
      }
    }
  }
}
</script>
